import React, { useEffect, useState } from "react"

import { ebayBanner } from "./ebayfooter.module.css"

export default props => {
  const [ebayFooterContent, setEbayFooterContent] = useState(<p>Loading...</p>)

  useEffect(() => {
    setEbayFooterContent(
      <ins
        className="epn-placement"
        data-config-id="5ef1f3585da29225ccf11f71"
        data-keyword={"white dwarf " + props.issueNumber + " -weekly"}
        onLoad={ebayFrameHandler()}
      ></ins>
    )
  }, [props.issueNumber])

  const ebayFrameHandler = () => {
    console.log("Ebay frame loading")
  }

  return <div className={ebayBanner}>{ebayFooterContent}</div>
}
